if (typeof document !== "undefined") {
    // Check all fields on submit
    document.addEventListener('submit', function (event) {

        var buttonLabel = document.getElementById('subscribe-button-label');
        var buttonSpinner = document.getElementById('subscribe-button-spinner');
        //var subscribeInput = document.getElementById('subscribe-input');
        //var subscribeSuccess = document.getElementById('subscribe-success');
        buttonLabel.classList.add('hidden');
        buttonSpinner.classList.remove('hidden');

        // Prevent form from submitting
        event.preventDefault();

        // Get all of the form elements
        var fields = event.target.elements;
        var email = fields.email.value;
        var name = fields.name.value;

        if (name.length > 0) {
            return;
        }

        try {
            var xhr = new XMLHttpRequest();
            // 2572697/zlmon6/
            xhr.open("POST", "https://hooks.zapier.com/hooks/catch/2572697/zlmon6/");
            xhr.send(JSON.stringify({ email: email }));
            document.getElementById('subscribe-input').classList.add("collapsed");
            document.getElementById('subscribe-success').classList.remove("collapsed");
            if (email.indexOf('@gmail.com') > -1 || email.indexOf('@frankwatching.com') > -1) {
                document.getElementById('gmail').classList.remove('hidden');
            } else if (email.indexOf('@outlook.com') > -1) {
                document.getElementById('outlook').classList.remove('hidden');
            } else if (email.indexOf('@hotmail.com') > -1) {
                document.getElementById('hotmail').classList.remove('hidden');
            } else if (email.indexOf('@icloud.com') > -1 || email.indexOf('@me.com') > -1) {
                document.getElementById('icloud').classList.remove('hidden');
            }
        } catch (e) {
            console.error(e);
        }

    }, false);

    /*function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    document.addEventListener("DOMContentLoaded", function (event) {
        setTimeout(function(){ console.log("Hello"); 
            var emailInput = document.getElementById("email");
            console.log(emailInput);
        }, 0);
        
        if (typeof (emailInput) != 'undefined' && emailInput != null) {
            console.log("ja e-mail input gevonden joh");
            emailInput.oninput = function () {
                var email = emailInput.value;
                var check = document.getElementById("check");
                console.log(email);
                if (validateEmail(email)) {
                    check.classList.remove("hidden");
                    emailInput.classList.add("validated");
                } else {
                    check.classList.add("hidden");
                    emailInput.classList.remove("validated");
                }
            }
        }
        
    });
    */


}
