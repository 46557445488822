import React from 'react';
import './script.js';
import './style.sass';
import gmail from '../../img/gmail.svg';
import outlook from '../../img/outlook.svg';
import icloud from '../../img/icloud.svg';

// 291435/zlhvvp
class Subscribe extends React.Component {
  render() {
    return (
      <div className="subscribe">
        <div className="subscribe-input" id="subscribe-input">
          <form action="https://hooks.zapier.com/hooks/catch/" method="post" id="subscribe" name="subscribe" className="subscribe" target="_blank">

            <h3>Ontvang mijn nieuwsbrief</h3>
            <p>Ontvang iedere twee weken mijn selectie met de beste tips en artikelen over optimalisatie &amp; analytics.</p>
            <label htmlFor="email">E-mail</label>
            <input type="email" name="email" id="email" placeholder="" required />
            <input type="name" name="name" id="name" placeholder="" style={{ display: `none` }} />
            <span id="check" className="hidden"><span role="img" aria-label="thumbs up">👍</span></span>
            <button type="submit" name="subscribe-button" id="subscribe-button" className="button"><span id="subscribe-button-label">Abonneren</span><span id="subscribe-button-spinner" className="loading monkey hidden"></span></button>
          </form>
        </div>
        <div className="subscribe-success collapsed" id="subscribe-success">
          <h3>Gelukt!</h3>
          <p>Je hebt een e-mail ontvangen om je adres te bevestigen.</p>
          <div id="gmail" className="hidden"><a href="https://mail.google.com/mail/u/0/#inbox" className="button button-ghost"><span className="email-icon"><img src={gmail} alt="Gmail" /></span> Naar je Gmail inbox</a></div>
          <div id="outlook" className="hidden"><a href="https://outlook.live.com/owa/" className="button button-ghost"><span className="email-icon"><img src={outlook} alt="Outlook" /></span> Naar je Outlook inbox</a></div>
          <div id="hotmail" className="hidden"><a href="https://outlook.live.com/owa/" className="button button-ghost"><span className="email-icon"><img src={outlook} alt="Outlook" /></span> Naar je Hotmail inbox</a></div>
          <div id="icloud" className="hidden"><a href="https://www.icloud.com/#mail" className="button button-ghost"><span className="email-icon"><img src={icloud} alt="iCloud" /></span> Naar je iCloud inbox</a></div>
        </div>
      </div>
    );
  }
}

export default Subscribe;
