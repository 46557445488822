import React from 'react';
import './style.sass';
import wa from '../../img/wa.svg';
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <p>Hi, ik ben <strong>Simon Koelewijn</strong>.</p>
        <p>Ik schrijf over UX, optimalisatie en analytics van websites en apps. De kennis die ik tijdens mijn studie (cognitieve) psychologie heb opgedaan over hoe onze hersenen werken gebruik ik om websites beter te maken.</p>
        <p>In mijn vrije tijd fiets ik graag <span role="img" aria-label="bike">🚲</span>, bezoek ik themaparken <span role="img" aria-label="rollercoaster">🎢</span> en shake ik cocktails<span role="img" aria-label="cocktail">🍸</span>.</p>
        <p><a href="mailto:simon@seems.nl">simon@seems.nl</a></p>
        <p><a href="https://api.whatsapp.com/send?phone=31639560737">+31 6 39 56 07 37 <img className="whatsapp" alt="WhatsApp" src={wa} /></a></p>
        <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <label
              style={{display: 'none'}}  
            >
              <input
                type="checkbox"
                onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                checked={theme === 'dark'}
              />{' '}
              Dark mode
          </label>
          )}
        </ThemeToggler>
      </div>
    );
  }
}

export default Footer;
